<template>
    <div class="specialOperations_page">
        <BannerImg :bannerImgUrl="formData.bannerImgUrl" :isList="true"></BannerImg>
        <div class="module1">
            <div class="">
                <CourseTitle :title="formData.whyTakeName" contText=""> </CourseTitle>
                <div class="why_textual_research">
                    <div class="carousel_box">
                        <el-carousel :interval="5000">
                            <el-carousel-item v-for="(item, index) in formData.specialApplicationWhyTakeParamDTOS"
                                :key="index">
                                <div class="el_carousel_cont display column">
                                    <div class="title">{{ item.whyTakeName }}</div>
                                    <div class="text_div">
                                        {{ item.whyTakeIntroduce }}
                                    </div>
                                    <img class="icon_img" :src="item.whyTakeImgUrl" alt="">
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>
        </div>
        <div class="module2">
            <div class="content">
                <CourseTitle :title="formData.applyConditionName" contText="">
                </CourseTitle>
                <div class="eligible ">
                    <div class="flex-center " :class="index % 2 != 0 ? 'list_fr' : ''"
                        v-for="(item, index) in formData.specialApplicationApplyConditionParamDTOS" :key="index">
                        <div class="list flex-center ">
                            <div class="fl display column">
                                <img class="icon_img" :src="item.applyConditionImgUrl" alt="">
                                <div class="num">0{{ index + 1 }}</div>
                            </div>
                            <div class="fr flex-center">
                                <div class="fr_text">{{ item.applyConditionName }} </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="btn display pointer" @click="service">
                    在线咨询，详细了解
                </div>

            </div>
        </div>
        <div class="module3">
            <div class="content">
                <CourseTitle :title="formData.operatingCertificateName" :contText="formData.operatingCertificateIntroduce"
                    :isWhite="1">
                </CourseTitle>
                <div class="main Between">
                    <div class="fl display column" v-if="index == 0"
                        v-for="(item, index) in formData.specialApplicationOperatingCertificateParamDTOS" :key="index">
                        <img class="fl_img" :src="item.operatingCertificateImgUrl" alt="">
                        <div class="_text">{{ item.operatingCertificateName }}</div>
                    </div>
                    <div class="fr display column" v-if="index == 1"
                        v-for="(item, index) in formData.specialApplicationOperatingCertificateParamDTOS" :key="index">
                        <img class="fr_img" :src="item.operatingCertificateImgUrl" alt="">
                        <div class="_text">{{ item.operatingCertificateName }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module4">
            <div class="content">
                <CourseTitle :title="formData.certificateInquiryName" contText="">
                </CourseTitle>
                <div class="onLine_inquiry display column">
                    <img class="onLine_inquiry_img" :src="formData.certificateInquiryImgUrl" alt="" />
                    <div class="btn display pointer" @click="inquireBtn">
                        立即查询
                    </div>
                </div>
            </div>
        </div>
        <div class="module5">
            <div class="">
                <CourseTitle :title="formData.newApplicationGuideName" contText="">
                </CourseTitle>
                <div class="content">
                    <!-- <div class="title">市应急管理局关于2023年上半年特种作业人员考试工作有关事项的通知 </div> -->
                    <div class="rich_text sidebar" v-html="formData.newApplicationGuideContent">
                    </div>
                </div>
            </div>
        </div>
        <div class="module6">
            <div class="">
                <CourseTitle :title="formData.trainingQualificationName"
                    :contText="formData.trainingQualificationIntroduce">
                </CourseTitle>
                <div class="content eligibility Between">
                    <div class="eligibility_fl" v-for="(item, index) in formData.trainingQualificationImgUrls" :key="index"
                        @mouseenter="handleMouseEnter(index)" @click="amplification(item)">
                        <img class="icon_img" :src="item" alt="" preview="1" preview-text="描述" />
                        <div class="mask_layer" v-if="isShow == index"></div>
                        <div class="eligibility_cont display column" v-if="isShow == index">
                            <img class="amplification" src="@/assets/img/specialOperations/amplification.png" alt="" />
                            <div class="btn display pointer">放大</div>
                        </div>
                    </div>
                    <!-- <div class="eligibility_fr">
                        <img class="icon_img" preview="1" preview-text="描述" src="@/assets/img/specialOperations/1.png"
                            alt="" />
                    </div> -->
                </div>
            </div>
        </div>
        <div class="module7">
            <CourseTitle :title="formData.finishingClassName" :contText="formData.finishingClassIntroduce">
            </CourseTitle>
            <div class=" content one_step_faster Between flex-wrap">
                <div class="list display column" v-for="(item, index) in formData.specialApplicationFinishingClassParamDTOS"
                    :key="index">
                    <img class="list_img" :src="item.finishingClassImgUrl" alt="" />
                    <div class="list_cont display column">
                        <div class="list_name">{{ item.finishingClassName }}</div>
                        <div class="btn display pointer" @click="service">报考咨询</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module8">
            <div class="content">
                <div class="formBox">
                    <div class="head">不同特种作业工种，如何收费？</div>
                    <div class="cont flex">
                        <div class="list">
                            <el-select v-model="paramsData.courseName" placeholder="请选择特种作业操作工种">
                                <el-option v-for="item in options" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="list userName">
                            <input type="text" placeholder="请输入姓名" v-model="paramsData.userName">
                        </div>
                        <div class="list userPhone">
                            <input type="text" maxlength="11" placeholder="请输入联系电话" v-model="paramsData.phoneNumber">
                        </div>
                        <div class="btn display pointer" @click="consultBtn">立即咨询</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module10">
            <div class="content">
                <CourseTitle :title="formData.applicationProcessName" contText="">
                </CourseTitle>
                <div class="process flex flex-wrap">
                    <div class="process_list flex-center column"
                        v-for="(item, index) in formData.specialApplicationProcessVOS" :key="index">
                        <img :src="item.applicationProcessImgUrl" alt="" />
                        <div class="process_num flex-center">
                            <div>{{ index + 1 < 10 ? 0 : '' }}</div>
                                    {{ index + 1 }}
                            </div>
                            <div class="line"></div>
                            <div class="process_name">{{ item.applicationProcessName }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="advantage">
                <CourseTitle :title="formData.golgaAdvantageName" contText="">
                </CourseTitle>
                <div class="content Between flex-wrap">
                    <div class="advantage_list flex-center"
                        v-for="(item, index) in formData.specialApplicationGolgaAdvantageParamDTOS" :key="index"
                        @mouseenter="mouseEnter(index)">
                        <img class="icon_img" :src="advantageIndex == index ? item.highlightIcon : item.icon" alt="" />
                        <div class="advantage_list_name">{{ item.golgaAdvantageName }}</div>
                        <div class="line"></div>
                        <div class="advantage_list_details">{{ item.golgaAdvantageIntroduce }}</div>
                    </div>
                </div>
            </div>
            <div class="selectGej">
                <CourseTitle :title="formData.chooseGolgaName" contText="">
                </CourseTitle>
                <div class="content flex flex-wrap">
                    <div class="selectGej_list " v-for="(item, index) in formData.specialApplicationChooseGolgaParamDTOS"
                        :key="index">
                        <div class="img_box" v-if="index % 2 != 0">

                            <img class="icon_up" src="@/assets/img/specialOperations/icon_up.png" alt="" />
                            <img class="icon_img" :src="item.chooseGolgaImgUrl" alt="" />
                        </div>
                        <div class="selectGej_list_cont flex-center column">
                            <div class="selectGej_list_cont_title">{{ item.chooseGolgaName }}</div>
                            <div class="selectGej_list_cont_details">{{ item.chooseGolgaIntroduce }}</div>
                            <div class="btn display pointer" @click="service">了解详情</div>
                        </div>
                        <div class="img_box" v-if="index % 2 == 0">
                            <img class="icon_img" :src="item.chooseGolgaImgUrl" alt="" />
                            <img class="icon_down" src="@/assets/img/specialOperations/icon_down.png" alt="" />
                        </div>

                    </div>
                </div>
            </div>
            <div class="policySubsidy">
                <CourseTitle :title="formData.policySubsidyName" contText="">
                </CourseTitle>
                <div class="content policySubsidyBox flex">
                    <div class="policySubsidy-fl flex-center column">
                        <div class="text">{{ formData.policySubsidyIntroduce }}
                        </div>
                        <div class="btn display pointer" @click="service">立即咨询申领条件</div>
                    </div>
                    <div class="policySubsidy-fr display">
                        <img class="icon_img" :src="formData.policySubsidyImgUrl" alt="" />
                    </div>
                </div>
            </div>
            <div class="coreCourse">
                <CoreCourses :coreCourseTitle="formData.coreCourseTitle"
                    :registrationCoreCourse="formData.specialCoreCourseVOS" :coreCourses="formData.specialCoreCourseVOS[0]">
                </CoreCourses>
            </div>
            <div class="teachin">
                <div>
                    <CourseTitle :title="formData.teachingTeamName" :contText="formData.teachingTeamIntroduce">
                    </CourseTitle>
                    <Lecturer :lecturerList="formData.teacherVOS"></Lecturer>
                </div>
            </div>
            <div class="teachingFacility">
                <div class="content">
                    <CourseTitle :title="formData.teachingFacilityName" contText="">
                    </CourseTitle>
                    <div class="main flex">
                        <div class="equipment_classification">
                            <div class="list display pointer" :class="activeIndex == index ? 'active' : ''"
                                v-for="(item, index) in formData.specialTeachingFacilityVOS" :key="index"
                                @click="changeBtn(item, index)">
                                {{ item.teachingFacilityName }}
                            </div>
                        </div>
                        <div class="equipment_classification_img">
                            <div class="carousel_box">
                                <el-carousel :interval="3000" indicator-position="none">
                                    <el-carousel-item v-for="(item, index) in teachingFacilitiesImg" :key="index">
                                        <img class="carousel_img pointer" :src="item" alt="" />
                                    </el-carousel-item>
                                </el-carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="module9">
                <div class="content">
                    <CourseTitle :title="formData.liveTeachingName" contText="">
                    </CourseTitle>
                    <div class="navBar display">
                        <div class="list display pointer" :class="navBarIndex == index ? 'activeList' : ''"
                            v-for="(item, index) in formData.specialLiveTeachingTypeVOS" :key="index"
                            @click="changeNav(item, index)">
                            {{ item.typeName }}
                        </div>
                    </div>
                    <div class="scene_teaching flex flex-wrap">
                        <div class="scene_teaching_list" v-for="(item, index) in sceneTeaching" :key="index">
                            <img :src="item.liveTeachingImgUrl" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="teachingVideo">

                <CourseTitle :title="formData.teachingVideoName" :contText="formData.teachingVideoIntroduce">
                </CourseTitle>
                <div class="navBar display">
                    <div class="list display pointer" :class="videoIndex == index ? 'activeList' : ''"
                        v-for="(item, index) in formData.specialApplicationTeachingVideoCategoryParamDTOS" :key="index"
                        @click="changeVideoBtn(item, index)">
                        {{ item.categoryName }}
                    </div>
                </div>

                <div class="why_textual_research">
                    <div class="carousel_box">
                        <el-carousel :interval="5000">
                            <el-carousel-item v-for="(item, index) in specialApplicationTeachingVideoParamDTOS"
                                :key="index">
                                <div class="el_carousel_cont display column">
                                    <video class="video_div" controls="controls" :playsinline="true"
                                        :src="item.teachingVideoVideoUrl"></video>
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>


            </div>
            <div class="studentStyle">
                <div class="content">
                    <CourseTitle :title="formData.studentStyleName" :contText="formData.studentStyleIntroduce">
                    </CourseTitle>
                    <div class="main ">
                        <img class="icon_img" :src="formData.studentStyleImgUrl" alt="" />
                    </div>
                </div>
            </div>

            <div class="module12">
                <SignUp :courseType="6" :courseId="this.formData.id"></SignUp>
            </div>
            <div class="imageMagnification display" v-if="imgShow" @click="imgShow = false">
                <img class="icon_img" :src="imgUrl" alt="">
                <div class="btn display pointer" @click="imgShow = false">关闭</div>
            </div>
        </div>
</template>
<script>
import SignUp from '@/components/signUp'
import BannerImg from '@/components/bannerImg'
import CourseTitle from './components/courseTitle.vue'
import CoreCourses from '@/components/coreCourses'
import Lecturer from '@/components/lecturer'
export default {
    components: {
        BannerImg,
        CourseTitle,
        CoreCourses,
        Lecturer,
        SignUp,
    },
    data() {
        return {
            imgShow: false,
            isShow: 0,
            activeIndex: 0,
            navBarIndex: 0,
            navBarIndexA: 0,
            advantageIndex: null,
            videoIndex: 0,
            formData: {},
            radio: null,
            guideList: [],
            imgUrl: '',
            gejAdvantage: [],
            equipmentClassification: [],
            navBar: [],
            sceneTeaching: [],
            process: [],
            yesCertificatea: [],
            noCertificatea: [],
            teachingFacilitiesImg: [],
            userQuestionBankParamDTOS: [],
            params: {
                userName: '',
                userPhone: '',
                userQuestionBankParamDTOS: [],
            },
            list: [],
            options: [{
                value: '低压电工',
                label: '低压电工'
            }, {
                value: '焊工',
                label: '焊工'
            }, {
                value: '高处安装、维护、拆除作业',
                label: '高处安装、维护、拆除作业'
            }, {
                value: '登高架设作业',
                label: '登高架设作业'
            }
            ],
            value: '',
            specialApplicationTeachingVideoParamDTOS: [],
            paramsData: {
                userName: "",
                phoneNumber: "",
                courseId: "",
                courseType: 6,
                courseName: "",
                email: "",
                companyName: "",
                questionContent: "",
                typeQualification: "",
                certificationProject: "",
                locationEnterprise: "",
                dateEstablishment: "",
            },
        }
    },
    mounted() {
        document.documentElement.scrollTop = 0
        this.init()
    },
    methods: {
        init() {
            this.api.findSpecialOperationsInfo().then((res) => {
                if (res.code == 0) {
                    res.data.specialCertifiedUndocumentedVOS.forEach((item) => {
                        if (item.type == 1) {
                            this.yesCertificatea.push(item)
                        } else {
                            this.noCertificatea.push(item)
                        }
                    })
                    res.data.specialGejAdvantageVOS.forEach((item, index) => {
                        this.gejAdvantage.forEach((data, indexs) => {
                            if (index == indexs) {
                                data.name = item.gejAdvantageName
                                data.gejAdvantageIntroduce = item.gejAdvantageIntroduce
                                data.bg = item.gejAdvantageImgUrl
                            }
                        })
                    })
                    res.data.specialTeachingFacilityVOS.forEach((item) => {
                        item.teachingFacilityImgUrl = item.teachingFacilityImgUrl.split(',')
                    })
                    res.data.specialQuestionBankVOS.forEach((item) => {
                        this.$set(item, 'radio', '')
                        this.$set(item, 'answer', '')
                    })
                    this.teachingFacilitiesImg =
                        res.data.specialTeachingFacilityVOS[0].teachingFacilityImgUrl
                    this.sceneTeaching =
                        res.data.specialLiveTeachingTypeVOS[0].specialLiveTeachingVOS

                    res.data.whyTakeImgUrls = res.data.whyTakeImgUrls.split(',')
                    res.data.trainingQualificationImgUrls = res.data.trainingQualificationImgUrls.split(',')
                    this.specialApplicationTeachingVideoParamDTOS = res.data.specialApplicationTeachingVideoCategoryParamDTOS[0].specialApplicationTeachingVideoParamDTOS
                    res.data.bannerImgUrl = res.data.bannerImgUrl.split(',')

                    this.formData = res.data
                    this.paramsData.courseId = res.data.id

                }
            })
        },
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
        mouseOver(item, index) {
            item.backgroundImage = { backgroundImage: 'url(' + item.bg + ')' }
        },
        mouseleave(item, index) {
            item.backgroundImage = ''
        },
        //切换教学设备
        changeBtn(item, index) {
            this.teachingFacilitiesImg = item.teachingFacilityImgUrl
            this.activeIndex = index
        },
        // 切换现场教学
        changeNav(item, index) {
            this.sceneTeaching = item.specialLiveTeachingVOS
            this.navBarIndex = index
        },
        getRadioRow(item, items, index) {
            this.formData.specialQuestionBankVOS[index].answer = item.label
            let obj = {
                questionBankId: item.id,
                questionBankName: item.label,
                answerId: items.id,
                answerContent: item.type == 2 ? item.radio + items.label : items.label,
            }
            this.params.userQuestionBankParamDTOS[index] = obj
        },
        consultBtn() {
            var reg = /^1(3|4|5|6|7|8|9)\d{9}$/
            if (this.paramsData.courseName == '') {
                return this.$message.error('请选择特种作业操作工种')
            } else if (this.paramsData.userName == '') {
                return this.$message.error('请输入您的姓名')
            } else if (!reg.test(this.paramsData.phoneNumber)) {
                return this.$message.error('请输入您的手机号')
            }
            this.api.addCourseApply(this.paramsData).then((res) => {
                if (res.code == 0) {
                    this.$message.success('提交成功！')
                    this.paramsData = {
                        userName: "",
                        phoneNumber: "",
                        courseId: "",
                        courseType: 6,
                        courseName: "",
                        email: "",
                        companyName: "",
                        questionContent: "",
                        typeQualification: "",
                        certificationProject: "",
                        locationEnterprise: "",
                        dateEstablishment: "",
                    }

                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        submitBtn() {
            var reg = /^1(3|4|5|6|7|8|9)\d{9}$/
            if (
                this.formData.specialQuestionBankVOS.length !=
                this.params.userQuestionBankParamDTOS.length
            ) {
                return this.$message.error('请填写完整信息')
            }
            if (this.params.userName == '') {
                return this.$message.error('请输入您的姓名')
            } else if (!reg.test(this.params.userPhone)) {
                return this.$message.error('请输入您的手机号')
            }
            this.api.addUserQuestionInfo(this.params).then((res) => {
                if (res.code == 0) {
                    this.$message.success('提交成功！')
                    this.params = {
                        userName: '',
                        userPhone: '',
                        userQuestionBankParamDTOS: [],
                    }
                    this.formData.specialQuestionBankVOS.forEach((item) => {
                        this.$set(item, 'radio', '')
                    })
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //特种作业证书官方在线查询平台网址
        inquireBtn() {
            window.open(this.formData.certificateInquiryLink, '_blank');
        },
        handleMouseEnter(index) {
            this.isShow = index
        },
        mouseEnter(index) {
            this.advantageIndex = index
        },
        changeVideoBtn(item, index) {
            this.videoIndex = index
            this.specialApplicationTeachingVideoParamDTOS = item.specialApplicationTeachingVideoParamDTOS
            console.log(item);
        },
        // 图片放大
        amplification(item) {
            this.imgUrl = item
            this.imgShow = true
        }
    },
}
</script>
<style lang="scss" scoped>
.specialOperations_page {


    .module1 {
        padding-bottom: 70px;

        .why_textual_research {
            width: 1400px;

            margin: 0 auto;



            .el_carousel_cont {
                margin-top: 40px;

                .title {
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 24px;
                }

                .text_div {
                    width: 720px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    margin-top: 30px;
                }

                .icon_img {
                    width: 950px;
                    height: 400px;
                    margin-top: 35px;
                }
            }

            ::v-deep.el-carousel__container {
                height: 615px;
                display: flex;
                align-items: center;
                justify-content: center;

            }

            ::v-deep.el-carousel {
                .el-carousel__item {}

                .el-carousel__container {

                    .el-carousel__arrow {
                        width: 48px;
                        height: 48px;
                        font-size: 24px;
                        background: rgba(51, 51, 51, 0.5);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                }
            }
        }

    }

    .module2 {
        width: 100%;
        height: 1070px;
        background: #fafafa;

        .eligible {
            width: 1200px;
            height: 678px;
            background: url('../../assets/img/specialOperations/bg1.png') no-repeat;
            background-size: 100% 100%;
            margin-top: 40px;
            position: relative;

            .list {
                width: 600px;
                height: 132px;
                background: url('../../assets/img/specialOperations/bg2.png') no-repeat;
                background-size: 100% 100%;


                .fl {
                    width: 124px;
                    height: 124px;
                    padding-left: 4px;

                    .icon_img {
                        width: 60px;
                        height: 60px;
                    }

                    .num {
                        font-size: 30px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FFFFFF;
                        margin-top: 3px;
                        line-height: 30px;
                    }
                }

                .fr {
                    height: 100px;
                    margin-left: 20px;

                    .fr_text {
                        width: 415px;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 24px;
                    }
                }
            }

            .list_fr {
                justify-content: flex-end;
            }
        }

        .btn {
            width: 428px;
            height: 48px;
            background: #FF881E;
            border-radius: 5px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            margin: 0 auto;
            margin-top: 50px;
        }

    }

    .module3 {
        height: 1000px;
        background: url('../../assets/img/specialOperations/bg3.png') no-repeat;
        background-size: 100% 100%;

        .main {
            width: 1200px;
            height: 622px;
            background: #FFFFFF;
            border-radius: 20px;
            margin-top: 45px;
            padding: 0 35px;
            box-sizing: border-box;

            .fl {
                .fl_img {
                    width: 400px;
                    height: 500px;
                }


            }

            .fr {
                .fr_img {
                    width: 700px;
                    height: 500px;
                }

                .fr_text {}
            }

            ._text {
                height: 23px;
                font-size: 24px;
                font-family: HarmonyOS Sans SC;
                font-weight: 400;
                color: #333333;
                margin-top: 22px;
            }
        }
    }

    .module4 {
        padding-bottom: 99px;

        .onLine_inquiry {
            margin-top: 38px;

            .onLine_inquiry_img {
                width: 950px;
                height: 730px;
                margin: 0 auto;
            }

            .btn {
                width: 428px;
                height: 48px;
                background: #FF881E;
                border-radius: 5px;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                margin: 0 auto;
                margin-top: 62px;
            }
        }


    }

    .module5 {
        height: 880px;
        background: url('../../assets/img/specialOperations/bg4.png') no-repeat;
        background-size: 100% 100%;

        .title {
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            margin-top: 24px;
            text-align: center;
        }

        .rich_text {
            width: 1200px;
            height: 600px;
            margin-top: 10px;
            overflow: hidden;
            overflow-y: scroll;
            padding-right: 40px;
        }
    }

    .module6 {
        height: 633px;

        .eligibility {
            margin-top: 36px;

            .icon_img {
                width: 580px;
                height: 360px;
            }

            .eligibility_fl {
                width: 580px;
                height: 360px;
                position: relative;

                .mask_layer {
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.48);
                    position: absolute;
                    top: 0px;
                    left: 0px;
                }

                .eligibility_cont {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 6;

                    .amplification {
                        width: 60px;
                        height: 60px;
                    }

                    .btn {
                        width: 114px;
                        height: 40px;
                        background: #FF881E;
                        border-radius: 5px;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FFFFFF;
                        margin-top: 17px;
                    }
                }
            }
        }

    }

    .module7 {
        background: #fafafa;

        .one_step_faster {
            margin-top: 50px;

            .list {
                width: 580px;
                margin-bottom: 60px;

                .list_img {
                    width: 580px;
                    height: 300px;
                }

                .list_cont {
                    width: 352px;
                    height: 114px;
                    background: #FFFFFF;
                    box-shadow: 0px 1px 3px 0px rgba(51, 51, 51, 0.52);
                    border-radius: 5px;
                    margin-top: -56px;
                    z-index: 6;

                    .list_name {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                        line-height: 30px;
                    }

                    .btn {
                        width: 135px;
                        height: 37px;
                        background: #FF881E;
                        border-radius: 19px;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FAFAFA;
                        margin-top: 13px;
                    }
                }
            }
        }
    }

    .module8 {
        width: 100%;
        height: 280px;
        background: url('../../assets/img/specialOperations/bg5.png') no-repeat;
        background-size: 100% 100%;

        .formBox {
            margin-left: 384px;
            padding-top: 74px;

            .head {
                font-size: 36px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FAFAFA;
                line-height: 30px;

            }

            .cont {
                margin-top: 34px;

                .list {
                    margin-right: 16px;

                    ::v-deep .el-input__inner {
                        width: 273px;
                        height: 54px;
                        background: #FFFFFF;
                        border: none;
                        border-radius: 0;
                        font-size: 16px;
                    }

                    input {
                        width: 100%;
                        height: 54px;
                        background: #FFFFFF;
                        padding-left: 14px;
                        box-sizing: border-box;
                        font-size: 16px;
                    }

                    .userName {
                        width: 123px;
                    }

                    .userPhone {
                        width: 163px;
                    }

                }

                .btn {
                    width: 201px;
                    height: 54px;
                    background: #FCC800;
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                }
            }
        }
    }

    .advantage {
        height: 620px;
        background: url('../../assets/img/specialOperations/bg6.png') no-repeat;
        background-size: 100% 100%;

        .content {
            margin-top: 30px;
        }

        .advantage_list {
            width: 580px;
            height: 160px;
            background: #fff;
            box-shadow: 0px 1px 6px 0px rgba(51, 51, 51, 0.25);
            padding-left: 40px;
            padding-right: 46px;
            box-sizing: border-box;
            margin-bottom: 29px;

            .icon_img {
                width: 72px;
                height: 83px;
            }

            .advantage_list_name {
                font-size: 30px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
                margin-left: 24px;
            }

            .line {
                width: 1px;
                height: 80px;
                background: #DEDEDE;
                margin-left: 47px;
                margin-right: 44px;
            }

            .advantage_list_details {
                width: 243px;
                height: 40px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                line-height: 24px;
            }
        }

        .advantage_list:hover {
            background: #003c7e;

            .advantage_list_name {
                color: #fff;
            }

            .line {
                background: #fff;
            }

            .advantage_list_details {
                color: #fff;
            }
        }
    }

    .selectGej {
        padding-bottom: 126px;

        .content {
            margin-top: 30px;
        }

        .selectGej_list {




            .img_box {
                width: 300px;
                height: 240px;
                position: relative;

                .icon_up {
                    width: 172px;
                    height: 41px;
                    position: absolute;
                    left: 22%;
                    bottom: 0px;
                }

                .icon_down {
                    width: 172px;
                    height: 41px;
                    position: absolute;
                    left: 22%;
                    top: 0px;
                }
            }

            .icon_img {
                width: 300px;
                height: 240px;
            }

            .selectGej_list_cont {
                width: 300px;
                height: 237px;
                padding-top: 36px;

                .selectGej_list_cont_title {
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 24px;
                }

                .selectGej_list_cont_details {
                    width: 240px;
                    height: 59px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    line-height: 22px;
                    margin-top: 20px;
                    text-align: center;
                }

                .btn {
                    width: 130px;
                    height: 40px;
                    background: #FF881E;
                    border-radius: 8px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-top: 25px;
                }
            }
        }
    }

    .policySubsidy {
        background: #fafafa;
        padding-bottom: 102px;

        .policySubsidyBox {
            height: 656px;
            background: url('../../assets/img/specialOperations/bg7.png') no-repeat;
            background-size: 100% 100%;

            .policySubsidy-fl {
                width: 556px;
                padding-top: 102px;

                .text {
                    width: 416px;
                    height: 192px;
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                }

                .btn {
                    width: 368px;
                    height: 48px;
                    background: #FF881E;
                    border-radius: 5px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-top: 70px;
                }
            }

            .policySubsidy-fr {
                margin-left: 40px;

                .icon_img {
                    width: 540px;
                    height: 560px;
                }
            }
        }
    }

    .teachin {
        padding-bottom: 120px;
    }

    .teachingFacility {
        height: 753px;
        background: url('../../assets/img/course/bg3.png') no-repeat;
        background-size: 100% 100%;

        .main {
            margin-top: 31px;

            .equipment_classification::-webkit-scrollbar {
                display: none;
            }

            .equipment_classification {
                width: 300px;
                height: 480px;
                background: #fff;
                overflow: hidden;
                overflow-y: scroll;

                .list {
                    width: 100%;
                    height: 120px;
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                }

                .active {
                    background: #003c7e;
                    color: #fff !important;
                }
            }

            .equipment_classification_img {
                width: 900px;

                .carousel_box {
                    width: 900px;
                    height: 480px;
                }

                .carousel_img {
                    width: 100%;
                    height: 100%;
                }

                ::v-deep.el-carousel__container {
                    height: 480px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                }

                ::v-deep.el-carousel {

                    .el-carousel__container {
                        .el-carousel__arrow {
                            width: 48px;
                            height: 48px;
                            font-size: 24px;
                            background: rgba(51, 51, 51, 0.5);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                    }
                }
            }
        }
    }

    .module9 {
        padding-bottom: 110px;

        .navBar {
            margin-top: 21px;

            .list {
                height: 46px;
                background: #eeeeee;
                border-radius: 3px;
                padding: 0px 36px;
                box-sizing: border-box;
                margin: 0px 9px;
            }

            .activeList {
                background: #003C7E !important;
                color: #fff;
            }
        }

        .scene_teaching {
            margin-top: 32px;

            .scene_teaching_list:nth-child(4n + 1) {
                margin-left: 0 !important;
            }

            .scene_teaching_list {
                margin-left: 26px;
                margin-bottom: 26px;

                img {
                    width: 280px;
                    height: 220px;
                }
            }
        }
    }

    .teachingVideo {
        height: 1048px;
        background: url('../../assets/img/specialOperations/bg8.png') no-repeat;
        background-size: 100% 100%;
        padding-bottom: 110px;

        .navBar {
            margin-top: 21px;

            .list {
                height: 46px;
                background: #eeeeee;
                border-radius: 3px;
                padding: 0px 36px;
                box-sizing: border-box;
                margin: 0px 9px;
            }

            .activeList {
                background: #003C7E !important;
                color: #fff;
            }
        }

        .why_textual_research {
            width: 1400px;
            margin: 0 auto;

            .el_carousel_cont {
                margin-top: 40px;


                .video_div {
                    width: 1200px;
                    height: 670px;
                }
            }

            ::v-deep.el-carousel__container {
                height: 700px;
                display: flex;
                align-items: center;
                justify-content: center;

            }

            ::v-deep.el-carousel {
                .el-carousel__item {}

                .el-carousel__container {
                    .el-carousel__arrow {
                        width: 48px;
                        height: 48px;
                        font-size: 24px;
                        background: rgba(51, 51, 51, 0.5);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                }
            }
        }



    }

    .module10 {
        padding-bottom: 59px;

        .process {
            margin-top: 21px;

            .process_list:nth-child(5n + 1) {
                margin-left: 0 !important;
            }

            .process_list {
                width: 228px;
                height: 248px;
                box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);
                margin-left: 15px;
                margin-bottom: 14px;

                img {
                    width: 60px;
                    height: 60px;
                    margin-top: 45px;
                }

                .process_num {
                    font-size: 36px;
                    font-family: Arial;
                    font-weight: bold;
                    color: #003c7e;
                    margin-top: 23px;
                    line-height: 20px;
                }

                .line {
                    width: 36px;
                    height: 3px;
                    background: #003c7e;
                    margin-top: 6px;
                }

                .process_name {
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #000000;
                    margin-top: 32px;
                }
            }
        }
    }

    .studentStyle {
        background: #fafafa;
        padding-bottom: 100px;

        .icon_img {
            width: 100%;
            height: 612px;
            margin-top: 45px;
        }
    }
}

.imageMagnification {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #000000;
    z-index: 9999999999999;

    .icon_img {
        width: 1190px;
        height: 793px;
    }

    .btn {
        width: 130px;
        height: 48px;
        background: #003C7E;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        position: absolute;
        top: 80px;
        right: 100px;
    }
}
</style>