<template>
    <div class="core_courses_page">
        <div class="content">
            <CourseTitle :isWhite="1" :title="coreCourseTitle" contText="">
            </CourseTitle>
            <div class="core_courses flex">
                <div class="coursesc_classification">
                    <div class="coursesc_classification_list display pointer"
                        :class="classificationIndex == index ? 'active' : ''"
                        v-for="(item, index) in registrationCoreCourse" :key="index" @click="changeBtn(item, index)">
                        {{
                            item.coreCourseName
                            ? item.coreCourseName
                            : item.electricianTrainingName
                        }}
                    </div>
                </div>
                <img class="coursesc_img" :src="coreCourses.coreCourseImgUrl
                        ? coreCourses.coreCourseImgUrl
                        : coreCourses.electricianTrainingImgUrl
                    " alt="" />
                <div class="coursesc_details">
                    <div class="classification_name">
                        {{ coreCourses.coreCourseName }}
                    </div>
                    <div class="rich_text" v-html="coreCourses.coreCourseIntroduce
                            ? coreCourses.coreCourseIntroduce
                            : coreCourses.electricianTrainingIntroduce
                        "></div>
                    <div class="btn display pointer" @click="service">
                        立即查看报名条件
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CourseTitle from '../views/homes/components/courseTitle.vue'
export default {
    props: {
        coreCourseTitle: {},
        registrationCoreCourse: {},
        coreCourses: {},
    },
    components: {
        CourseTitle,
    },
    data() {
        return {
            classificationIndex: 0,
        }
    },
    created() { },
    methods: {
        //点击核心课程分类
        changeBtn(item, index) {
            console.log(item)
            this.classificationIndex = index
            this.coreCourses = item
        },
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    },
}
</script>
<style lang="scss" scoped>
.core_courses_page {
    min-height: 764px;
    background: url('../assets/img/course/bg17.png') no-repeat;
    background-size: 100% 100%;

    .core_courses {
        width: 100%;
        height: 500px;
        background: #ffffff;
        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);
        border-radius: 0px 8px 8px 8px;
        padding: 30px 20px;
        padding-left: 5px;
        box-sizing: border-box;
        margin-top: 21px;

        // .coursesc_classification::-webkit-scrollbar{
        //     display: none;
        // }
        .coursesc_classification::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 1px;
            height: 100px;
            padding-bottom: 20px;
            /*高宽分别对应横竖滚动条的尺寸*/
        }

        .coursesc_classification::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            width: 1px;
            height: 100px;
            border-radius: 5px;
            background: #054081 !important;
        }

        .coursesc_classification::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 0;
            background: white;
        }

        .active {
            border: 1px solid #003c7e !important;
            color: #003c7e !important;
            font-weight: bold;
        }

        .coursesc_classification {
            width: 195px;
            height: 439px;
            padding: 0 15px;
            box-sizing: border-box;
            overflow: hidden;
            overflow-y: scroll;

            .coursesc_classification_list {
                width: 160px;
                background: #fafafa;
                border: 1px solid #fafafa;
                border-radius: 8px;
                font-size: 20px;
                color: #333333;
                margin-bottom: 15px;
                padding: 16px 8px;
                box-sizing: border-box;
                text-align: center;
            }
        }

        .coursesc_img {
            width: 570px;
            height: 440px;
            margin-left: 28px;
        }

        .coursesc_details {
            margin-left: 32px;
            position: relative;

            .classification_name {
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #003c7e;
                line-height: 24px;
            }

            .rich_text {
                width: 350px;
                margin-top: 29px;
            }

            .btn {
                width: 344px;
                height: 48px;
                background: #ff881e;
                border-radius: 5px;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                position: absolute;
                left: 0;
                bottom: 0;
            }
        }
    }
}
</style>